import React, {useEffect, useRef, useState} from "react";
import './vqn-dash/style.css';

const LoggedInPage = () => {
    const videoRef = useRef(null);
    const photoRef = useRef(null);
    const stripRef = useRef(null);
    const [my_text, set_my_text] = useState(
        "Pixel location"
    )
    const [my_secondary_text, set_my_secondary_text] = useState(
        "Accumulated Info"
    )
    const currentFile = useRef(null);
    let pixels = useRef({"image": null, "points": []})
    let counter = useRef(0)
    let image_fname = useRef(null);
    const colors = ["red", "red", "lime", "lime", "aqua", "aqua"]
    const labels = ["pick", "pick_rot", "reference", "reference_rot", "place", "place_rot"]

    const prevXY = useRef(null);

    useEffect(() => {
        getVideo();
    }, [videoRef]);

    const loadRealSense = () => {
        // showing.current = "block"
        // console.log(showing
        console.log("load realsense")
    }

    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({video: {width: 300}})
            .then(stream => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
            })
            .catch(err => {
                console.error("error:", err);
            });
    };

    const paintToCanvas = () => {
        let video = videoRef.current;
        let photo = photoRef.current;
        let ctx = photo.getContext("2d");

        const width = 320;
        const height = 240;
        photo.width = width;
        photo.height = height;

        return setInterval(() => {
            ctx.drawImage(video, 0, 0, width, height);
        }, 200);
    };

    const takePhoto = () => {
        // TODO: connect to RealSense server and show live stream
        let photo = photoRef.current;
        let strip = stripRef.current;

        console.warn(strip);

        const data = photo.toDataURL("image/jpeg");

        console.warn(data);
        const link = document.createElement("a");
        link.href = data;
        link.setAttribute("download", "myWebcam");
        link.innerHTML = `<img src='${data}' alt='thumbnail'/>`;
        strip.insertBefore(link, strip.firstChild);
    };

    const loadPhoto = () => (e) => {
        currentFile.current = null;
        resetPixels()

        let filepath = e.target.files[0]
        let url = URL.createObjectURL(filepath);
        let img = new Image();
        img.src = url;

        let photo = photoRef.current;
        let ctx = photo.getContext("2d");

        img.onload = function () {
            photo.width = img.width;
            photo.height = img.height;
            ctx.drawImage(img, 0, 0);
        }

        pixels.current["image"] = filepath.name
        set_my_secondary_text(JSON.stringify(pixels.current))
        image_fname.current = filepath.name
        currentFile.current = filepath
    }

    const mousePos = () => (evt) => {
        let cvs = photoRef.current
        let ctx = cvs.getContext("2d");
        var rect = cvs.getBoundingClientRect();
        var x = parseInt(evt.clientX - rect.left);
        var y = parseInt(evt.clientY - rect.top);
        set_my_text("Pixel y = " + y + ", x = " + x + ", Num points = " + pixels.current["points"].length)
    }

    const mouseClick = () => (evt) => {
        let cvs = photoRef.current
        let ctx = cvs.getContext("2d");
        var rect = cvs.getBoundingClientRect();
        var x = parseInt(evt.clientX - rect.left);
        var y = parseInt(evt.clientY - rect.top);

        ctx.strokeStyle = "white"
        if (colors.length > counter.current) {
            ctx.strokeStyle = colors[counter.current]
        }
        ctx.lineWidth = 3.0
        ctx.beginPath();

        ctx.moveTo(x - 10, y - 10);
        ctx.lineTo(x + 10, y + 10);

        ctx.moveTo(x + 10, y - 10);
        ctx.lineTo(x - 10, y + 10);
        ctx.stroke();

        let label = "unknown"

        // If has previous, let's draw a line in between
        if (prevXY.current !== null) {
            let prev_x = prevXY.current[0]
            let prev_y = prevXY.current[1]
            ctx.beginPath()
            ctx.moveTo(prev_x, prev_y)
            ctx.lineTo(x, y)
            ctx.stroke()
            prevXY.current = null;
            label = "unknown_rot"
        } else {
            prevXY.current = [x, y]
        }
        if (labels.length > counter.current) {
            label = labels[counter.current]
        }

        let res = {"x": x, "y": y, "label": label, "color": ctx.strokeStyle}
        pixels.current["points"].push(res)
        set_my_text("Pixel y = " + y + ", x = " + x + ", Num points = " + pixels.current["points"].length)
        set_my_secondary_text(JSON.stringify(pixels.current))
        counter.current += 1
    }

    const handleSave = () => {
        let jsonData = pixels.current
        const fileData = JSON.stringify(jsonData, null, 4);
        const blob = new Blob([fileData], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        if (image_fname.current === undefined) {
            throw new Error("Bruh")
        }
        let image_name = image_fname.current.split(".")[0]
        link.download = image_name + ".json";
        link.href = url;
        link.click();
    }

    const resetPixels = () => {
        pixels.current = {"image": null, "points": []}
        set_my_secondary_text(JSON.stringify(pixels.current))
        counter.current = 0

        let photo = photoRef.current;
        if (currentFile.current) {
            let url = URL.createObjectURL(currentFile.current);
            let img = new Image();
            img.src = url;
            pixels.current["image"] = currentFile.current.name
            set_my_text("Pixel location")
            set_my_secondary_text(JSON.stringify(pixels.current))

            let cvs = photoRef.current
            let ctx = cvs.getContext("2d");
            ctx.beginPath()
            ctx.clearRect(0, 0, cvs.width, cvs.height)
            ctx.closePath()
            img.onload = function () {
                photo.width = img.width;
                photo.height = img.height;
                ctx.drawImage(img, 0, 0);
            }
        } else {
            let cvs = photoRef.current
            let ctx = cvs.getContext("2d");
            ctx.beginPath()
            ctx.clearRect(0, 0, cvs.width, cvs.height)
            ctx.closePath()
        }
    }

    //todo: add a dedicated canvas capturing element, for getting the mouse clicks.
    //todo: use graphQL for the backend.
    return (
        <div>
            <button onClick={() => loadRealSense()}>Load RealSense Live Stream</button>
            {/*<button onClick={() => takePhoto()}>Take Photo (from RealSense)</button>*/}
            <p>Load Image: <input type="file" onChange={loadPhoto()}/></p>
            {/*<div id="video">*/}
            {/*    <h2>Video Live Stream</h2>*/}
            {/*    <video onCanPlay={() => paintToCanvas()} ref={videoRef}/>*/}
            {/*</div>*/}
            <h2>Canvas</h2>
            <p>For each of the following, specify two points where (1) the location, (2) second point to specify the
                rotation</p>
            <ol>
                <li style={{color: 'red'}}>Pick</li>
                <li style={{color: 'green'}}>Object Reference Point</li>
                <li style={{color: 'blue'}}>Place (wrt reference point)</li>
            </ol>
            <p>{my_text}</p>
            <p>{my_secondary_text}</p>
            <button onClick={() => resetPixels()}>Reset</button>
            &nbsp;
            <button onClick={() => handleSave()}>Save to JSON</button>
            <br/>
            <canvas ref={photoRef} onMouseMove={mousePos()} onClick={mouseClick()}/>
            <div>
                <div ref={stripRef}/>
            </div>
        </div>
    );
};
export default LoggedInPage;